<template>
  <section class="checkout-page d-flex flex-row justify-between ">
    <div class="btn-back mobile d-flex" @click="back()">
      <img src="@/assets/icons/Arrow-left.svg" alt="">
      <h6 class="title">Back</h6>
    </div>
    <div class="information">
      <div class="btn-back desktop d-flex" @click="back()">
        <img src="@/assets/icons/Arrow-left.svg" alt="">
        <h6 class="title">Back</h6>
      </div>
      <div class="payment-steps d-flex flex-row align-center">
        <div class="step d-flex flex-row align-center">
          <div class="number passed d-flex flex-row align-center justify-center"><img src="@/assets/icons/ok.svg" alt=""></div>
          <h5 class="title passed">Delivery Info</h5>
        </div>
        <div class="line passed"></div>
        <div class="step d-flex flex-row align-center">
          <div class="number d-flex flex-row align-center justify-center">2</div>
          <h5 class="title">Personal Info</h5>
        </div>
        <div class="line"></div>
        <div class="step d-flex flex-row align-center">
          <div class="number d-flex flex-row align-center justify-center">3</div>
          <h5 class="title">Payment</h5>
        </div>
      </div>
      <div class="tile-benefits">
        <div class="left">
          <h3 class="title">Don’t miss your benefits</h3>
          <ul class="list">
            <li class="list__item">
              <div class="icon">
                <img src="@/assets/icons/okay.svg" alt="">
              </div>
              <span class="m1">Geen</span> bezorgkosten
            </li>
            <li class="list__item">
              <div class="icon">
                <img src="@/assets/icons/okay.svg" alt="">
              </div>
              Bezorging op <span class="m2">zondag</span>
            </li>
          </ul>
        </div>
        <div class="right d-flex flex-column justify-between">
          <div class="btn-container d-flex flex-row align-center justify-end">
            <div class="btn-create-account" @click="transitionToSignUp">Creëer account</div>
          </div>
          <div class="info d-flex flex-column align-center">
            <div class="title"> Already have an account?</div>
            <div class="log-in" @click="transitionToSignIn">Log in</div>
          </div>
        </div>
      </div>
      <form @submit.prevent>
        <div class="input-containers top d-flex">
          <input class="fname"
                 :class="{'error':$v.personalInfo.fname.$error}"
                 v-model="$v.personalInfo.fname.$model"
                 type="text"
                 placeholder="First name*">
          <input class="lname"
                 type="text"
                 placeholder="Last name*"
                 :class="{'error':$v.personalInfo.lname.$error}"
                 v-model="$v.personalInfo.lname.$model">
        </div>
        <div class="input-containers medium d-flex">
          <VuePhoneNumberInput v-model="personalInfo.phone"
                               default-country-code="NL"
                               dark-color="#262627"
                               :dark="true"
                               :no-example="true"
                               :color="'#262627'"
                               :only-countries="['NL']"
                                size="lg"/>
          <input class="email"
                 type="text"
                 placeholder="Email*"
                 :class="{'error':$v.personalInfo.email.$error}"
                 v-model="$v.personalInfo.email.$model">
        </div>
        <div class="input-containers d-flex">
          <input class="street"
                 type="text"
                 placeholder="Street"
                 :class="{'error':$v.personalInfo.street.$error}"
                 v-model="$v.personalInfo.street.$model">
          <input class="house"
                 type="text"
                 placeholder="House №"
                 :class="{'error':$v.personalInfo.house.$error}"
                 v-model="$v.personalInfo.house.$model">
        </div>
        <div class="input-containers d-flex">
          <input class="addition-info"
                 type="text"
                 placeholder="Additional info (optional)"
                 :class="{'error':$v.personalInfo.additional.$error}"
                 v-model="$v.personalInfo.additional.$model">
        </div>
        <div class="input-containers d-flex">
          <input class="post-code"
                 type="text"
                 placeholder="Post Code"
                 :class="{'error':$v.personalInfo.postCode.$error}"
                 v-model="$v.personalInfo.postCode.$model">
          <input class="city"
                 type="text"
                 placeholder="City/Town"
                 :class="{'error':$v.personalInfo.city.$error}"
                 v-model="$v.personalInfo.city.$model">
        </div>
        <div class="next-step d-flex flex-row justify-between align-center">
          <div class="btn-continue-shop">
            Continue shopping
          </div>
          <div class="btn-next-step" @click="nextPage">
            <h6>Next step</h6>
          </div>
        </div>
      </form>
    </div>
    <CheckoutBasket />
  </section>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required,email} from 'vuelidate/lib/validators';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: "CheckoutPersonalInfo",
  mixins:[validationMixin],
  data(){
    return{
      value:null,
      personalInfo:{
        fname:'',
        lname:'',
        street:'',
        house:'',
        additional:'',
        postCode:'',
        city:'',
        email:'',
        phone:''
      },
      submitStatus: null,
    }
  },
  validations:{
    personalInfo:{
      fname:{
        required
      },
      lname:{
        required
      },
      phone:{
        required
      },
      email:{
        required,
        email:email
      },
      street:{

      },
      city:{

      },
      additional: {

      },
      postCode: {

      },
      house:{

      }
    },
  },
  computed:{
    deliveryData(){
      const personalInfo = this.$store.getters['receiverPersonalInfo'];
      if(personalInfo !== null) this.personalInfo = personalInfo;
      return 1
    }
  },
  methods:{
    nextPage(){
      console.log(1)
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.$store.dispatch('setPersonalInfo',this.personalInfo);
        this.$router.push('/checkoutPayment')
      }
    },
    goToShop(){
      this.$router.push('/')
    },
    back(){
      this.$router.push('/checkout')
    },
    transitionToSignIn() {
      this.$router.push('/signIn')
    },
    transitionToSignUp() {
      this.$router.push('/signUp')
    }
  },
  components:{
    CheckoutBasket:()=> import('../CheckoutBasket/CheckoutBasket'),
    VuePhoneNumberInput
  },
  mounted() {
    this.deliveryData
  }
}
</script>

<style scoped lang="scss">
@import "CheckoutPersonalInfo";

</style>